<template>
  <div class="otp-inputs" style="display: flex; flex-direction: row;justify-content:center">
      <OtpInput
        :ref="otpInput"
        input-classes="otp-input"
        separator=" "
        :num-inputs="4"
        :should-auto-focus="true"
        :is-input-num="true"
        @on-change="handleOnChange"
        @on-complete="handleOnComplete"
      />
      <!-- <button @click="handleClearInput()">Clear Input</button> -->
  </div>
</template>

<script>
import OtpInput from "@bachdgvn/vue-otp-input";

export default {
  props:['otpCode', "otpInput"],
  mounted(){
  },
  components: {
    OtpInput,
  },
  methods: {
    handleOnComplete(value) {
      this.$emit('update:otpCode', value); 
    },
    handleOnChange(value) {
      this.$emit('update:otpCode', value);
    },
    // handleClearInput() {
    //   this.$refs.otpInput.clearInput();
    // },
  },
};
</script>

<style lang="scss">
.rtl .otp-inputs {
    direction: ltr;
}
.otp-inputs {
      direction: ltr;
}
.otp-input {
  width: 70px;
  height: 50px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  text-align: center;
  background:rgba(246, 246, 246, 1);
  &.error {
    border: 1px solid red !important;
  }
  &:focus{
  background:#fff;
    border: 3px solid #505050 !important;
  }
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
